import { StatusEnum } from '@/types/common.types';
import { CouponTypeTranslatedEnum } from '@/types/coupon.types';
import { ICouponMainInfo, OutputDiscountTypeEnum } from '@/types/couponSteps.types';
import { RestrictionByUserEnum } from '@/types/user.types';
import { CouponAdpterType } from '@/utils/couponUtils';

export const APP_ID = 'COUPON_MANAGEMENT';
export const APP_ENTITY = 'COUPON_CAMPAIGNS';

export const defaultDevtoolsOptions = {
  name: 'Coupon Campaigns - Cupons',
  enabled: localStorage.getItem('devtools') === 'true',
};

export const MONEY_MASK_CONFIG = {
  mask: Number,
  signed: true,
  scale: 2,
  normalizeZeros: false,
  padFractionalZeros: true,
  min: 0,
  lazy: false,
};

export const emptyNewCoupon: ICouponMainInfo = {
  firstStep: {
    couponCode: '',
    couponName: '',
    additionalInformation: '',
    type: undefined,
    specifyUsers: undefined,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  },
  secondStep: {
    couponValue: '',
    orderMinimum: '',
    maxDiscount: '',
    firstOrder: false,
    totalPerUser: 1,
    totalNumber: 1,
  },
  thirdStep: {
    users: [],
    deliveryCentersIds: [],
    insertToOriginalWallet: false,
    insertToUserWallet: true,
    itemsIds: [],
    outputDiscountType: OutputDiscountTypeEnum.ORDER_LEVEL,
    usersFileName: undefined,
    file: [],
  },
};

export const newCouponMock: ICouponMainInfo = {
  firstStep: {
    couponCode: '',
    couponName: '',
    additionalInformation: '',
    type: undefined,
    specifyUsers: undefined,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  },
  secondStep: {
    couponValue: '',
    orderMinimum: '',
    maxDiscount: '',
    firstOrder: false,
    totalPerUser: 1,
    totalNumber: 1,
  },
  thirdStep: {
    users: [],
    deliveryCentersIds: [],
    insertToOriginalWallet: false,
    insertToUserWallet: true,
    itemsIds: [],
    outputDiscountType: OutputDiscountTypeEnum.LINE_ITEM,
    usersFileName: undefined,
    file: [],
  },
};

export const filledNewCoupon: ICouponMainInfo = {
  firstStep: {
    couponCode: 'couponCode',
    couponName: 'couponName',
    additionalInformation: 'additionalInformation',
    type: CouponTypeTranslatedEnum.NOMINAL,
    specifyUsers: RestrictionByUserEnum.WITH_USER,
    startDate: 'Mon Apr 01 2024',
    startTime: '1:00 AM',
    endDate: 'Fri Apr 12 2024',
    endTime: '2:30 AM',
  },
  secondStep: {
    couponValue: '10',
    orderMinimum: '',
    maxDiscount: '',
    firstOrder: false,
    totalPerUser: 1,
    totalNumber: 0,
  },
  thirdStep: {
    users: ['90271199-4e2d-4d5e-ad13-c89115da260e', 'ef0fa329-94bd-4aee-b52f-24193d0e30f8'],
    enforcementUser: [],
    deliveryCentersIds: ['64386148e7d48107fab68e71'],
    insertToOriginalWallet: false,
    insertToUserWallet: true,
    itemsIds: ['282', '10', '447', '283', '8', '9'],
    outputDiscountType: OutputDiscountTypeEnum.ORDER_LEVEL,
    usersFileName: '',
    file: [],
  },
};

export const emptyNewCouponAdapter: CouponAdpterType = {
  couponCode: '',
  couponName: '',
  additionalInformation: '',
  type: CouponTypeTranslatedEnum.NOMINAL,
  status: StatusEnum.ACTIVE,
  value: '0',
  maxDiscount: '0',
  orderMinimum: '0',
  firstOrder: true,
  startDate: new Date().toDateString(),
  endDate: new Date().toDateString(),
  startTime: '00:00',
  endTime: '23:59',
  totalPerUser: 0,
  totalNumber: 0,
  productLevelDiscount: false,
  deliveryCenter: {
    id: '',
    name: '',
  },
  users: [],
  usersFileName: '',
  items: [],
  updatedAt: undefined,
  createdBy: '',
  vendorPromotionId: '',
  quantityLimit: 1,
  quantityLimitPerUser: 1,
  insertToOriginalWallet: false,
  insertToUserWallet: false,
  encryptable: true,
};

export const ROUTER_BASENAME = '/coupon';

export const COUPON_ROUTES = {
  HOME: ROUTER_BASENAME,
  COUPON_MANAGEMENT: `${ROUTER_BASENAME}/management`,
  CREATE_COUPON: `${ROUTER_BASENAME}/management/create/:country`,
  DETAILS_COUPON: `${ROUTER_BASENAME}/management/:id`,
  MGM: `${ROUTER_BASENAME}/mgm`,
  MGM_RC: `${ROUTER_BASENAME}/mgm/rc`,
  MGM_RWC: `${ROUTER_BASENAME}/mgm/rwc`,
  MGM_MANAGE: `${ROUTER_BASENAME}/mgm/manage`,
};
