import MessageMap from '../i18n';

const ptBR: MessageMap = {
  TEST: {
    TRANSLATE: 'Translate',
    MISSING: 'Only English option available',
  },
  SIDEMENU: {
    HOME: 'Home',
    COUPON_MANAGEMENT: 'Gerenciamento de Cupons',
    MGM: 'Member Get Member (MGM)',
  },
  HOMEPAGE: {
    TITLE: 'Campanhas de cupom',
    COUPON_MANAGEMENT: {
      TITLE: 'Gerenciamento de Cupons',
      DESCRIPTION: 'Gerencie campanhas de cupons, crie e edite cupons.',
    },
    MGM: {
      TITLE: 'Member Get Member (MGM)',
      DESCRIPTION:
        'Gerencie as configurações de cupom de referência (RC) e recompensa (RWC) e disponibilize-as para todas as zonas TaDa.',
    },
  },
  ACTIONS: {
    GO_BACK: 'Voltar',
    BACK: 'Voltar',
    CANCEL: 'Cancelar',
    CLOSE: 'Fechar',
    CONFIRM: 'Confirm',
    SAVE: 'Salvar',
    SEARCH: 'Buscar',
    APPLY: 'Aplicar',
    COPY: 'Copiar',
    EDIT: 'Editar',
    SELECT: {
      DEFAULT: 'Selecione',
      ALL: 'Selecionar todos',
      ONE_OR_MORE: 'Select one or more',
    },
    REMOVE: 'Remover',
    YES: 'Sim',
    NO: 'Não',
    CREATE_COUPON: 'Criar cupom',
    SHOW_DETAILS: 'Show details',
    CREATE: 'Create',
    CONTINUE: 'Continuar',
    PUBLISH: 'Publicar',
    TRY_AGAIN: 'Tente novamente',
    COPY_ENCRYPTION: 'Copiar criptografia',
    ENCRYPT: 'Criptografar',
  },
  TOAST: {
    COUPON_CREATED: 'Coupon created',
    COUPON_UPDATED: 'Coupon updated',
    UNABLE_UPDATE_COUPON: 'Unable to update coupon.',
    UNABLE_GET_COUPON_DATA: 'Unable to get coupon data.',
  },
  COUNTRIES: {
    HN: 'Honduras',
    SV: 'El Salvador',
    PA: 'Panamá',
    BO: 'Bolívia',
    DO: 'República Dominicana',
    PY: 'Paraguai',
    PE: 'Peru',
    EC: 'Equador',
    ZA: 'África do Sul',
    CO: 'Colombia',
    MX: 'México',
    AR: 'Argentina',
    BR: 'Brasil',
  },
  VERSION: {
    VERSION_APP: 'Versão',
    SERVER: 'Servidor',
    RIGHTS_RESERVED: 'Anheuser-Busch InBev S.A. Todos los derechos reservados.',
  },
  UNAUTHORIZED_PAGE: {
    TITLE: 'Oops, access denied!',
    DESCRIPTION: "It seems you don't have permission to access this page.",
    ACTION: 'Go back to the homepage',
  },
  ENTITIES: {
    DELIVERY_CENTERS: {
      TITLE_PLURAL: 'Delivery Centers',
      TITLE_SINGLE: 'Delivery Center',
      ADDED: 'Delivery Center added',
      ADDED_QUANTITY: '{quantity} delivery centers added',
      ADDED_YET: 'No delivery centers added yet',
      SELECT_OR_UNSELECT: 'Select or unselect to assign a Delivery Center to the Coupon',
      SELECTED: 'Delivery centers selected',
      SELECT_FROM_THE_MENU: 'Select delivery centers from the menu above',
      COUPON_WILL_BE_ADDED:
        'Coupon will be added to the coupon wallet of consumers residing in regions served by elegible delivery centers.',
    },
    PRODUCTS: {
      TITLE_PLURAL: 'Produtos',
      TITLE_SINGLE: 'Produto',
      BRAND: 'Marca',
      CATEGORY: 'Categoria',
      SELECTED_PRODUCTS: 'Produtos selecionados',
      SELECTED_CATEGORIES_BRANDS_PRODUCTS:
        'Selecione ou desmarque para atribuir ou remover categorias, marcas ou produtos do cupom',
      THE_BENEFIT_OF_THIS_COUPON:
        'The benefit of this coupon will only apply to these products and not to the total order.',
      PRODUCTS_CONSIDERED_FOR_THE_COUPON_WILL_DISPLAY_HERE:
        'Os produtos considerados para o cupom serão exibidos aqui.',
    },
    USER: {
      ADD_COUPON_TO_WALLET: 'Add coupon to wallet',
      ADD_CSV_FILE: 'Carregar arquivo CSV',
      ADD_USERS: 'Adicionar usuários',
      ADD_EMAILS: 'Adicionar e-mails',
      ADD_USER_WALLET: 'Adicionar à carteira da pessoa usuária',
      USER_LIST: 'Lista de usuários',
      USER_TAB: 'Usuários',
      USER_WALLET_DESCRIPTION:
        'By activating this, the coupon will appear in the coupon wallet of the user listed above automatically.',
      DESCRIPTION: 'Adicione usuários ou deixe em branco para aplicar a todos.',
      UPLOAD_A_CSV_FILE: 'Carregar um arquivo CSV',
      UPLOAD_A_CSV_FILE_DESCRIPTION:
        'O próximo passo é confirmar se os dados carregados estão corretos ou não.',
      UPLOAD_CSV_FILE_MESSAGE: 'Solte o CSV aqui para iniciar o envio ou',
      UPLOAD_CSV_FILE_BUTTON: 'Procurar arquivos',
      CSV_FILE_UPLOADED: 'Arquivo CSV enviado',
      CONFIRM_EMAILS_UPLOADED: 'Confirmar e-mails enviados',
      CONFIRM_EMAILS_UPLOADED_DESCRIPTION_PART_1: 'Esses dados serão usados para oferecer',
      CONFIRM_EMAILS_UPLOADED_DESCRIPTION_PART_2: 'cupons de desconto aos usuários.',
      INVALID_EMAILS: 'E-mails não válidos',
      INVALID_EMAILS_TOOLTIP:
        'Alguns e-mails são de usuários não registrados ou de domínios banidos e sempre aparecerão como não válidos.',
      INVALID_EMAILS_DESCRIPTION_PART_1: 'Estes são os e-mails não válidos disponíveis no arquivo',
      INVALID_EMAILS_DESCRIPTION_PART_2:
        'carregado. Por favor, ajuste o arquivo CSV e refaça o envio.',
      UPLOAD_THE_ADJUSTED_CSV_FILE: 'Carregar o arquivo CSV ajustado',
      UPLOAD_THE_ADJUSTED_CSV_FILE_DESCRIPTION:
        'Carregue novamente o arquivo CSV ajustado, após corrigir os e-mails inválidos acima.',
      INVALID_FILE:
        'Seu arquivo não parece ser um arquivo CSV real. Faça upload de um arquivo CSV real e tente novamente.',
      THE_FILE_IS_EMPTY: 'O arquivo está vazio',
    },
    COUPONS: {
      TITLE_PLURAL: 'Cupons',
      TITLE_SINGLE: 'Cupom',
      PROPERTIES: {
        COUPON_NAME: 'Nome do cupom',
        COUPON_NAME_EXAMPLE: 'Exemplo: Boas-vindas à mágica!',
        REDEEM_CODE: 'Código de resgate',
        REDEEM_CODE_EXAMPLE: 'Exemplo: CORONA10',
        TYPE: {
          TITLE: 'Tipo',
          NOMINAL: 'Nominal',
          NOMINAL_EXAMPLE: 'Exemplo: R$5 de desconto',
          PERCENTAGE: 'Porcentagem',
          PERCENTAGE_EXAMPLE: 'Exemplo: 5% de desconto',
          FREE_DELIVERY: 'Free delivery',
        },
        STATUS: 'Status',
        MAX_DISCOUNT: 'Desconto máximo',
        PRODUCT_LEVEL_DISCOUNT: 'Desconto do produto',
        ELIGIBLE_DELIVERY: 'Centros de entrega elegíveis',
        ELIGIBLE_DELIVERY_DESCRIPTION: 'You can specify eligible delivery centers for this coupon.',
        VALUE: 'Value',
        ORDER_MINIMUM: 'Pedido mínimo',
        START_DATE: 'Data inicial',
        START_TIME: 'Horário inicial',
        END_DATE: 'Data final',
        END_TIME: 'Horário final',
        REDEMPTION_PER_CUSTOMER: 'Resgate por usuário',
        REDEMPTION_PER_CUSTOMER_INFO:
          'Se aplicar este cupom apenas no primeiro pedido, não poderá definir o limite de resgate por usuário.',
        REDEMPTION_BY_TOTAL_QUANTITY: 'Resgate por quantidade total',
        REDEMPTION_BY_TOTAL_QUANTITY_INFO:
          'Se especificar usuários por e-mail, não poderá limitar a quantidade de resgate.',
        ADDITIONAL_INFORMATION: 'Informação adicional',
        ADDITIONAL_INFORMATION_EXAMPLE:
          'Exemplo: O cupom só pode ser resgatado em produtos Corona disponíveis. Limite de um por pedido. O desconto será aplicado ao item elegível de menor preço. O cupom não pode ser aplicado junto com outros descontos.',
        LEGAL_DETAILS: 'Detalhes legais e especificações.',
        COUPON_PRICE: 'Preço do cupom',
        COUPON_VALUE: 'Valor do cupom',
        PERCENTAGE_HINT: 'Até 100%',
        APPLY_COUPON: 'Aplicar cupom somente no primeiro pedido',
        APPLY_COUPON_TO_ALL_DELIVERY_CENTERS: 'Apply coupon to all delivery centers',
        FIRST_ORDER: 'Primeira compra',
        PREVIEW: {
          TITLE: 'Visualização',
          EXAMPLE: {
            TITLE: 'Boas-vindas à mágica!',
            DESCRIPTION:
              'Comece a festa com Corona! Receba R$10 de desconto na compra de um pacote com 24 Coronas.',
          },
        },
        COUNTRY: 'Country',
        EXPIRES: 'Expira em',
        SELECT_THE_RESTRICTION_SETTINGS_YOU_WANT_BELOW: 'Selecione as restrições que deseja:',
        SET_RESTRICTION_BY_PRODUCT: 'Definir restrição por Produto?',
        SET_RESTRICTION_BY_PRODUCT_TOOLTIP:
          'O desconto será aplicado diretamente no valor do produto com restrição, e não para todo o valor do pedido.',
        NO_RESTRICTION_BY_PRODUCT: 'Sem restrição por produto',
        WITH_RESTRICTION_BY_PRODUCT: 'Com restrição por produto',
        SET_USER_RESTRICTION_BY_USER_EMAIL: 'Definir restrição de usuários por e-mail?',
        SET_USER_RESTRICTION_BY_USER_EMAIL_TOOLTIP:
          'Se restringir usuários, o cupom será aplicado apenas para os mesmos.',
        NO_USER_RESTRICTIONS: 'Sem restrição de usuários',
        WITH_USER_RESTRICTIONS: 'Com restrição de usuários',
        SELECT_THE_COUPON_TYPE: 'Selecione o tipo de cupom:',
        RESTRICTIONS_DISABLE_HINT: 'Se optar por especificar o produto, este campo será desativado',
        USE_BRAZE_TO_SEND_COUPON_TO_WALLET: 'Usar Braze para encarteiramento',
        SEND_COUPON_TO_WALLET_BY_BRAZE: 'Enviar cupom para a carteira pelo Braze',
      },
    },
    BRAZE: {
      ENCRYPTION_COMPLETE: 'Criptografia finalizada',
      ENCRYPTION_COPIED: 'Criptografia copiada!',
      UNABLE_TO_ENCRYPT_TITLE: 'Não foi possível criptografar',
      GO_TO_BRAZE_AND_PASTE_ENCRYPTION: 'Vá para a Braze e cole a criptografia no Editor.',
    },
  },
  COUPON_LIST: {
    TITLE: 'Campanhas de cupons',
    PAGINATION: {
      QUANTITY_INDICATOR: 'of',
      PAGE_SIZE_OPTIONS: 'Items per page: {options}',
    },
    EMPTY: {
      TITLE: 'No coupon with the selected filters',
      MESSAGE: 'Try selecting other filters.',
    },
    LOADING: {
      TITLE: 'Loading',
      MESSAGE: "We are preparing the coupon's list for you.",
    },
    FILTERED: {
      TITLE: 'No Coupons yet',
      MESSAGE: 'The Coupons will be displayed here.',
      NOT_EXPIRED: 'Not expired',
    },
    ERROR: {
      400: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      401: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'Contact our support team to review your account settings.',
      },
      403: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'Contact our support team to review your account settings.',
      },
      404: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: "We couldn't perform this action. Try again.",
      },
      500: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      502: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      503: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      CUSTOM: {
        ENCRYPTION_CRITERIA_ERROR:
          'O cupom selecionado não corresponde aos critérios de criptografia.',
        UNABLE_TO_ENCRYPT:
          'Ocorreu um erro em nosso sistema.  Por favor, tente novamente mais tarde.',
      },
    },
  },
  FILTERS: {
    PLACEHOLDERS: {
      COUNTRY: 'Select a country',
      STATUS: 'Select a status',
      SEARCH_BY_COUPON_CODE: 'Search by coupon code',
      DATE: 'dd/mm/yyyy',
    },
  },
  STATUS: {
    ALL: 'All statuses',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    EXPIRED: 'Expired',
  },
  FIELDS: {
    SELECT_AN_OPTION: 'Escolha uma opção',
    OPTIONAL: 'Opcional',
    REQUIRED: {
      LABEL: 'Obrigatório',
      GENERIC: 'Required field',
      REDEEM_CODE: 'Enter characters without spaces',
      DATE: 'Select a date',
      ADDITIONAL_INFORMATION: 'Enter additional information',
      COUPON_VALUE: 'Enter a price',
    },
    FORMAT: {
      GENERIC: 'Invalid format',
    },
    RADIO: {
      LIMITED: 'Limitado',
      UNLIMITED: 'Ilimitado',
    },
  },
  LAYOUT: {
    GRID: 'Grid',
    LIST: 'List',
  },
  COUPON: {
    STEPS: {
      MAIN_INFORMATION: 'Informações principais',
      MAIN_INFORMATION_DESCRIPTION: 'Definir nome do cupom, tipo e período.',
      RESTRICTIONS: 'Restrições',
      RESTRICTIONS_DESCRIPTION: 'Definir preço do cupom e limite de resgate.',
      TARGETING: 'Segmentação',
      TARGETING_DESCRIPTION: 'Definir elegibilidade: centros de entrega, produtos e usuários.',
      EDIT_TARGETING: 'Editar segmentação',
      SUMMARY_TITLE: 'Resumo',
      SUMMARY_DESCRIPTION: 'Revisar e publicar.',
      COUPON_CAMPAIGNS: 'Campanhas de cupons',
      CREATE_COUPON: 'Criar cupom',
    },
  },
  MONEY_MASK: {
    THOUSANDS_SEPARATOR: '.',
    RADIX: ',',
  },
  MGM: {
    //TODO: Translate
    BREADCRUMBS: 'Manage MGM',
    TITLE: 'Manage MGM parameters',
    SELECT_TYPE: 'Select the type of the coupon to manage the parameters',
    RC: {
      TITLE: 'Referral coupon (RC)',
      DESCRIPTION:
        'Referral coupon, with no use limit, valid only for the first purchase, which has no expiration date',
    },
    RWC: {
      TITLE: 'Reward coupon (RWC)',
      DESCRIPTION:
        'Reward coupon, received by the referrer after the referral completes their first purchase.',
    },
    MANAGE_COUPONS_BY_USER: 'Manage coupons by user',
  },
};

export default ptBR;
