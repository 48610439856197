import { styled } from '@hexa-ui/theme';

export const FooterVersionContainer = styled('footer', {
  paddingBottom: '1.5rem',
  paddingTop: '$8',
  marginTop: 'auto',
  width: '100%',

  p: {
    color: 'rgba(20, 20, 20, 0.32)',
    fontFamily: 'Roboto',
  },
});
