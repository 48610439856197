import TicketIcon from '@/components/TicketIcon/TicketIcon';
import { COUPON_ROUTES } from '@/config/constants';
import { TOGGLE_LIST } from '@/config/featureToggles';
import { ChartTree, Home } from '@hexa-ui/icons';
import { useSidebar as useSideBarService } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import useFeatureToggle from '../useFeatureToggle/useFeatureToggle';

export const HomeIcon = (): React.ReactElement => <Home />;
export const CouponListIcon = (): React.ReactElement => <TicketIcon />;
export const MGMIcon = (): React.ReactElement => <ChartTree />;

const useSidebar = () => {
  const { formatMessage } = useIntl();
  const canRenderMGM = useFeatureToggle(TOGGLE_LIST.MGM);

  const items = [
    {
      id: 'home',
      title: formatMessage({ id: 'SIDEMENU.HOME' }),
      icon: HomeIcon,
      path: COUPON_ROUTES.HOME,
    },
    {
      id: 'coupons',
      title: formatMessage({ id: 'SIDEMENU.COUPON_MANAGEMENT' }),
      icon: CouponListIcon,
      path: COUPON_ROUTES.COUPON_MANAGEMENT,
    },
  ];

  if (canRenderMGM) {
    items.push({
      id: 'mgm',
      title: formatMessage({ id: 'SIDEMENU.MGM' }),
      icon: MGMIcon,
      path: COUPON_ROUTES.MGM,
    });
  }

  return useSideBarService({ items, utils: [] });
};

export default useSidebar;
