import Can from '@/components/Can/Can';
import PageHeader from '@/components/PageHeader/PageHeader';
import TicketIcon from '@/components/TicketIcon/TicketIcon';
import { COUPON_ROUTES } from '@/config/constants';
import { TOGGLE_LIST } from '@/config/featureToggles';
import { Heading, Paragraph } from '@hexa-ui/components';
import { ChartTree } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Card, Container, ContainerCards } from './CouponHomePage.styles';

const CouponHomePage = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const breadcrumbs = [
    {
      name: formatMessage({ id: 'HOMEPAGE.TITLE' }),
      active: true,
      href: COUPON_ROUTES.HOME,
    },
  ];

  return (
    <Container data-testid="home-page">
      <PageHeader title={formatMessage({ id: 'HOMEPAGE.TITLE' })} breadcrumbs={breadcrumbs} />
      <ContainerCards>
        <Card
          data-testid="card-coupon-management"
          onClick={() => {
            navigate(COUPON_ROUTES.COUPON_MANAGEMENT);
          }}
          border="medium"
          elevated="large"
        >
          <TicketIcon id="icon-coupon-management" />
          <div>
            <Heading size="H4">{formatMessage({ id: 'HOMEPAGE.COUPON_MANAGEMENT.TITLE' })}</Heading>
            <Paragraph size="small">
              {formatMessage({ id: 'HOMEPAGE.COUPON_MANAGEMENT.DESCRIPTION' })}
            </Paragraph>
          </div>
        </Card>
        <Can featureToggle={TOGGLE_LIST.MGM}>
          <Card
            data-testid="card-mgm"
            onClick={() => {
              navigate(COUPON_ROUTES.MGM);
            }}
            border="medium"
            elevated="large"
          >
            <ChartTree id="icon-mgm" />
            <div>
              <Heading size="H4">{formatMessage({ id: 'HOMEPAGE.MGM.TITLE' })}</Heading>
              <Paragraph size="small">
                {formatMessage({ id: 'HOMEPAGE.MGM.DESCRIPTION' })}
              </Paragraph>
            </div>
          </Card>
        </Can>
      </ContainerCards>
    </Container>
  );
};

export default CouponHomePage;
