import { Heading as _Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Div = styled('div', {
  display: 'flex',
  alignItems: 'center',

  '[data-testid="tooltip"]': {
    height: '32px',
    marginLeft: '$2',
    color: '$semanticInfoBasis',
  },

  'div:first-child': {
    display: 'flex',
    alignSelf: 'baseline',
  },
});

export const Heading = styled(_Heading, {
  display: 'flex',
  gap: '$2',
  marginBottom: '$2 !important',

  variants: {
    hasError: {
      true: {
        color: '$semanticErrorText',
      },
      false: {
        color: '$interfaceLabelPrimary',
      },
    },
    tooltip: {
      true: {
        fontSize: '16px !important',
      },
    },
    card: {
      true: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '24px !important',

        i: {
          width: '1.25rem',
          height: '1.25rem',
          display: 'flex',
          alignItems: 'center',
        },

        svg: {
          width: '1.25rem',
          height: '1.25rem',
          color: '$semanticInfoText',
          marginLeft: '$2',
        },
      },
    },
  },
});
