import Router from '@/Router';
import FooterVersion from '@/components/FooterVersion/FooterVersion';
import useSidebar from '@/hooks/useSideBar';
import { GridContainer, SideBar } from './AppBarConfig.styles';

const AppBarConfig = (): React.JSX.Element => {
  useSidebar();

  return (
    <SideBar>
      <GridContainer sidebar type="fluid">
        <Router />
        <FooterVersion />
      </GridContainer>
    </SideBar>
  );
};

export default AppBarConfig;
