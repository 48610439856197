import { Tooltip } from '@hexa-ui/components';
import { Info } from '@hexa-ui/icons';
import React from 'react';
import { Div, Heading } from './HeadingStyled.styles';

interface IHeadingStyled {
  cardHeader?: boolean;
  dataTestId?: string;
  dataTestIdTooltip?: string;
  hasError?: boolean;
  tooltipIcon?: React.ReactNode;
  placement?: 'top' | 'right';
  size?: 'H4' | 'H3' | 'H2' | 'H1' | 'H5';
  title: string | React.ReactNode;
  tooltip?: boolean;
  tooltipText?: string;
  icon?: React.JSX.Element | React.ReactNode;
}

const HeadingStyled: React.FC<IHeadingStyled> = ({
  dataTestId,
  dataTestIdTooltip,
  hasError = false,
  tooltipIcon,
  placement = 'top',
  size = 'H5',
  title,
  tooltip = false,
  tooltipText,
  cardHeader = false,
  icon,
}) => {
  return (
    <Div className="header-tooltip">
      {icon}
      <Heading
        data-testid={dataTestId}
        hasError={hasError}
        tooltip={tooltip}
        size={size}
        card={cardHeader}
      >
        {title}
      </Heading>
      {tooltipText && (
        <Tooltip data-testid={dataTestIdTooltip} placement={placement} text={tooltipText}>
          {tooltipIcon ? tooltipIcon : <Info size="large" />}
        </Tooltip>
      )}
    </Div>
  );
};

export default HeadingStyled;
