const RCIcon = () => {
  return (
    <svg
      data-testid="rc-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="55"
      fill="none"
      viewBox="0 0 72 55"
    >
      <path
        fill="#006EDB"
        d="M20.12 37c0-.828.648-1.5 1.446-1.5h2.892c.798 0 1.446.672 1.446 1.5s-.648 1.5-1.446 1.5h-2.892c-.798 0-1.445-.672-1.445-1.5"
      ></path>
      <path
        fill="#006EDB"
        fillRule="evenodd"
        d="M28.795 13h-.188c-1.33.013-2.485.958-2.806 2.298-.344 1.304-1.486 2.208-2.789 2.208s-2.445-.904-2.789-2.208c-.321-1.34-1.475-2.285-2.806-2.298h-.188c-3.99.005-7.224 3.36-7.229 7.5v24c0 2.485 1.942 4.5 4.337 4.5h3.08c1.33-.013 2.485-.958 2.806-2.298.344-1.304 1.486-2.208 2.789-2.208s2.445.904 2.789 2.208c.321 1.34 1.475 2.285 2.806 2.298h3.08c2.395 0 4.337-2.015 4.337-4.5v-24c-.005-4.14-3.238-7.495-7.229-7.5m2.892 33-3.099-.094c-.698-2.618-3.003-4.422-5.62-4.4S18.074 43.371 17.417 46h-3.08c-.798 0-1.445-.672-1.445-1.5v-6h2.891c.799 0 1.446-.672 1.446-1.5s-.647-1.5-1.446-1.5h-2.891v-15c0-2.485 1.942-4.5 4.337-4.5l.207.095c.696 2.6 2.975 4.4 5.576 4.405 2.636-.023 4.935-1.862 5.628-4.5h.155c2.396 0 4.337 2.015 4.337 4.5v15h-2.891c-.799 0-1.446.672-1.446 1.5s.647 1.5 1.446 1.5h2.892v6c0 .828-.648 1.5-1.446 1.5"
        clipRule="evenodd"
      ></path>
      <path
        fill="#006EDB"
        d="M67.186 12.89a1.125 1.125 0 0 0 1.484-1.686L60.796 3.33a1 1 0 0 0-.105-.092v-.001c-.2-.156-.441-.234-.682-.236h-.017a1.12 1.12 0 0 0-.786.33l-7.875 7.874-.094.106a1.125 1.125 0 0 0 .094 1.486l.105.093c.442.343 1.08.312 1.485-.093l5.955-5.954v13.033l.008.131a1.125 1.125 0 0 0 2.242-.131V6.842l5.954 5.954z"
      ></path>
    </svg>
  );
};

export default RCIcon;
