const TicketIcon = ({ id }: { id?: string }) => {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#141414"
    >
      <path d="M10.06 15c0-.414.324-.75.723-.75h1.446c.4 0 .723.336.723.75s-.324.75-.723.75h-1.446a.737.737 0 01-.723-.75z"></path>
      <path
        fillRule="evenodd"
        d="M14.398 3h-.094c-.666.007-1.243.479-1.404 1.149-.172.652-.742 1.104-1.394 1.104-.651 0-1.222-.452-1.394-1.104C9.95 3.479 9.374 3.007 8.708 3h-.094C6.62 3.002 5.002 4.68 5 6.75v12C5 19.993 5.971 21 7.169 21h1.54c.665-.006 1.242-.479 1.403-1.149.172-.652.743-1.104 1.394-1.104.652 0 1.222.452 1.394 1.104.161.67.738 1.143 1.404 1.149h1.54c1.197 0 2.168-1.007 2.168-2.25v-12c-.002-2.07-1.62-3.748-3.614-3.75zm1.445 16.5l-1.549-.047c-.349-1.309-1.502-2.21-2.81-2.2-1.309.011-2.447.933-2.776 2.247h-1.54a.737.737 0 01-.722-.75v-3h1.446c.399 0 .722-.336.722-.75a.737.737 0 00-.722-.75H6.446v-7.5c0-1.243.97-2.25 2.168-2.25l.104.047c.348 1.3 1.488 2.2 2.788 2.203 1.318-.012 2.468-.93 2.814-2.25h.078c1.197 0 2.168 1.007 2.168 2.25v7.5h-1.445c-.4 0-.723.336-.723.75s.323.75.723.75h1.445v3c0 .414-.323.75-.723.75z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default TicketIcon;
