import { styled } from '@stitches/react';

export const Container = styled('div', {
  display: 'block',
  paddingBottom: 24,
});

export const ContainerButtons = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const ContainerTile = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 22,
  p: {
    color: '$interfaceLabelSecondary !important',
  },
});
