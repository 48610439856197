import HeadingStyled from '@/components/HeadingStyled/HeadingStyled';
import BreadcrumbComponent from './Breadcrumb/Breadcrumb';
import { Container, ContainerButtons, ContainerTile } from './PageHeader.style';
import { PageHeaderProps } from './PageHeader.types';

export default function PageHeader({
  Buttons,
  breadcrumbs,
  title,
}: Readonly<PageHeaderProps>): React.JSX.Element {
  return (
    <Container data-testid="page-header">
      {breadcrumbs && <BreadcrumbComponent items={breadcrumbs} />}

      <ContainerTile>
        {title && <HeadingStyled size="H1" dataTestId="page-title" title={title} />}
        {Buttons && (
          <ContainerButtons>
            <Buttons />
          </ContainerButtons>
        )}
      </ContainerTile>
    </Container>
  );
}
