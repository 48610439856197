import { Breadcrumb } from '@hexa-ui/components';
import { Link } from 'react-router-dom';
import { BreadcrumbItem } from '../PageHeader.types';

const BreadcrumbComponent = ({ items }: { items: BreadcrumbItem[] }) => {
  return (
    <Breadcrumb.Root>
      <Breadcrumb.HomeItem asChild>
        <Link to="/coupon" />
      </Breadcrumb.HomeItem>
      {items.map((item) => (
        <Breadcrumb.Item key={item.name} asChild isCurrentPage={item.active}>
          <Link to={item.href}>{item.name}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb.Root>
  );
};

export default BreadcrumbComponent;
