import PageHeader from '@/components/PageHeader/PageHeader';
import RCIcon from '@/components/RCIcon/RCIcon';
import RWCIcon from '@/components/RWCIcon/RWCIcon';
import { COUPON_ROUTES } from '@/config/constants';
import { Card, Heading, Paragraph } from '@hexa-ui/components';
import { Search } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Button, Content, GroupCards, PageContainer } from './CouponMGMPage.styles';

const CouponMGMPage = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const breadcrumbs = [
    {
      name: formatMessage({ id: 'HOMEPAGE.TITLE' }),
      active: false,
      href: COUPON_ROUTES.HOME,
    },
    {
      name: formatMessage({ id: 'MGM.BREADCRUMBS' }),
      active: true,
      href: COUPON_ROUTES.MGM,
    },
  ];

  const handleRedirectTo = (path: string) => () => {
    navigate(path);
  };

  return (
    <PageContainer data-testid="mgm-page">
      <PageHeader title={formatMessage({ id: 'MGM.TITLE' })} breadcrumbs={breadcrumbs} />
      <Content>
        <Heading alignment="center" size="H3">
          {formatMessage({ id: 'MGM.SELECT_TYPE' })}
        </Heading>
        <GroupCards>
          <Card onClick={handleRedirectTo(COUPON_ROUTES.MGM_RC)} border="medium" elevated="large">
            <RCIcon />
            <div>
              <Heading size="H4">{formatMessage({ id: 'MGM.RC.TITLE' })}</Heading>
              <Paragraph size="small">{formatMessage({ id: 'MGM.RC.DESCRIPTION' })}</Paragraph>
            </div>
          </Card>
          <Card onClick={handleRedirectTo(COUPON_ROUTES.MGM_RWC)} border="medium" elevated="large">
            <RWCIcon />
            <div>
              <Heading size="H4">{formatMessage({ id: 'MGM.RWC.TITLE' })}</Heading>
              <Paragraph size="small">{formatMessage({ id: 'MGM.RWC.DESCRIPTION' })}</Paragraph>
            </div>
          </Card>
        </GroupCards>
        <Button
          onClick={handleRedirectTo(COUPON_ROUTES.MGM_MANAGE)}
          variant="secondary"
          size="medium"
          leading
          icon={Search}
        >
          {formatMessage({ id: 'MGM.MANAGE_COUPONS_BY_USER' })}
        </Button>
      </Content>
    </PageContainer>
  );
};

export default CouponMGMPage;
